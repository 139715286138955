/* @jsx jsx */
import {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '@utils/helpers'
import GraphQLErrorList from '@components/graphql-error-list'
import Seo from '@components/seo'
import Layout from '@containers/layout'
import {jsx} from '@emotion/react'
import Header from '@organisms/header'
import ContentWrapper from '@molecules/contentWrapper'
import Videos from '@organisms/videos'
const get = require('lodash.get')

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const {site, videos} = data
  const {seo, global, header, footer} = site
  const videosPerPage = 16
  const allVideos = mapEdgesToNodes(videos)

  const [videoQueue, setVideoQueue] = useState([])
  const [searchTerm, setSearchTerm] = useState(false)
  const [videosToShow, setVideosToShow] = useState([])

  const updateVideos = (page, range, items) => { setVideosToShow(items) }

  const searchVideos = term => {
    return allVideos.filter(video => {
      if (video.title.toLowerCase().includes(term.toLowerCase())) return true
      return false
    })
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  useEffect(() => {
    setVideoQueue(allVideos)
  }, [])

  useEffect(() => {
    if (!searchTerm) return
    if (searchTerm === ':::RESET:::') {
      updateVideos(1, `0...${videosPerPage}`, videoQueue.slice(0, videosPerPage))
    } else {
      updateVideos(1, `0...${videosPerPage}`, searchVideos(searchTerm))
    }
  }, [searchTerm])

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        image={header.logo}
      />
      <ContentWrapper>
        <Header title={seo.title} logoWidth={400} setSearchTerm={setSearchTerm} {...header} />
        <Videos
          videos={videoQueue}
          videosToShow={videosToShow}
          perPage={videosPerPage}
          searchTerm={searchTerm}
          action={updateVideos}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
        title
        description
        keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
    videos: allSanityVideo(sort: {order: DESC, fields: _updatedAt}) {
      edges {
        node {
          id
          _rawDescription(resolveReferences: {maxDepth: 10})
          pilot {
            _id
            handle
            social {
              facebook
              instagram
            }
            avatar {
              asset {
                _id
                fluid {
                  base64
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          title
          slug {
            current
          }
          thumbnail {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`
